import React, { useState, useRef } from 'react';
import './DesktopView.css';

// Import images
import logoImage from './assets/images/logo.png';
import rectangleImage from './assets/images/Rectangle 1.png';
import backgroundImage from './assets/images/1e2c232342f65f856ba145923213b3c6.png';

let BASE_URL = "https://dev-cms.glono.in/api/v2/estep/warranty";
// let BASE_URL = "http://0.0.0.0:8001/api/v2/estep/warranty";

const DesktopView = () => {
  const [formData, setFormData] = useState({
    customerName: '',
    contactNumber: '',
    email: '',
    productName: '',
    dateOfPurchase: '',
    invoice: null,
  });

  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      invoice: file,
    });
  };

  const handleSubmit = () => {
    // Validate all fields
    if (
      !formData.customerName ||
      !formData.contactNumber ||
      !formData.email ||
      !formData.productName ||
      !formData.dateOfPurchase ||
      !formData.invoice
    ) {
      alert("Please fill out all fields and upload an invoice.");
      return;
    }

    const formdata = new FormData();
    formdata.append("customer_name", formData.customerName);
    formdata.append("phone_number", formData.contactNumber);
    formdata.append("email", formData.email);
    formdata.append("product_name", formData.productName);
    formdata.append("date_of_purchase", formData.dateOfPurchase);
    formdata.append("invoice", formData.invoice);
  
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };
  
    fetch(BASE_URL, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        alert("Data submitted successfully!");
        window.location.reload();
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="desktop-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="parent">
        <div className="top-container">
          <img src={rectangleImage} alt="Rectangle" />
          <img src={logoImage} className="image2" alt="Logo" />
        </div>
        <div className="container">
          <h1 id="h1-wr">WARRANTY REGISTRATION</h1>
          <div className="warranty-form">
            <h3>Name</h3>
            <input 
              type="text" 
              id="input-box" 
              placeholder="Eg. Karan Sharma" 
              name="customerName"
              value={formData.customerName}
              onChange={handleChange}
            />

            <div className="contact-email">
              <div className="form-group">
                <h3>Contact Number</h3>
                <input 
                  type="text" 
                  id="contact-text" 
                  placeholder="Eg. 8587933843" 
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <h3>Email</h3>
                <input 
                  type="text" 
                  id="email-text" 
                  placeholder="Eg. karansharma@gmail.com" 
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            <h3>Product Name</h3>
            <input 
              type="text" 
              id="input-box" 
              placeholder="Eg. E-Step Toyota fortuner 2016 With LED Light" 
              name="productName"
              value={formData.productName}
              onChange={handleChange}
            />

            <div className="contact-email">
              <div className="form-group">
                <h3>Date of Purchase</h3>
                <input 
                  type="date" 
                  id="date-text" 
                  placeholder="DD/MM/YYYY" 
                  name="dateOfPurchase"
                  value={formData.dateOfPurchase}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <h3>Upload Invoice</h3>
                <input 
                  type="file" 
                  id="file-upload" 
                  ref={fileInputRef} 
                  onChange={handleFileChange}
                />
              </div>
            </div>

            <button id="submit-btn" onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopView;
