import React, { useState, useEffect } from 'react';
import './MobileView.css';

// Import images
import backgroundImage from './assets/images/mobile.png';
import topBorderImage from './assets/images/combined.png';

let BASE_URL = "https://dev-cms.glono.in/api/v2/estep/warranty";
// let BASE_URL = "http://0.0.0.0:8001/api/v2/estep/warranty";

const MobileView = () => {
  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    email: '',
    product: '',
    purchaseDate: '',
    invoice: null,
  });

  const [formValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const isFormValid = Object.values(formData).every(value => !!value);
    setFormValid(isFormValid);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const newValue = type === 'file' ? e.target.files[0] : value;

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleSubmit = () => {
    // Validate all fields
    if (
      !formData.name ||
      !formData.contact ||
      !formData.email ||
      !formData.product ||
      !formData.purchaseDate ||
      !formData.invoice
    ) {
      alert("Please fill out all fields and upload an invoice.");
      return;
    }

    setIsLoading(true); // Set loading state to true

    const formdata = new FormData();
    formdata.append("customer_name", formData.name);
    formdata.append("phone_number", formData.contact);
    formdata.append("email", formData.email);
    formdata.append("product_name", formData.product);
    formdata.append("date_of_purchase", formData.purchaseDate);
    formdata.append("invoice", formData.invoice);
  
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };
  
    fetch(BASE_URL, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        setIsLoading(false); // Set loading state to false
        alert("Data submitted successfully!");
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false); // Set loading state to false
      });
  };

  return (
    <div className="mobile-container">
      <img src={backgroundImage} className="mb-background-image" alt="Background" />
      <img src={topBorderImage} className="mb-top-border-image" alt="Top Border" />
      <div className="mb-form-container">
        <div className="mb-form-content">
          <mb-h1>WARRANTY REGISTRATION</mb-h1>
          <mb-form>
            <mb-label htmlFor="name">Name</mb-label>
            <input 
              type="text" 
              name="name" 
              placeholder="Eg. Karan Sharma" 
              onChange={handleChange}
              required 
            />
            <mb-label htmlFor="contact">Contact</mb-label>
            <input 
              type="text" 
              name="contact" 
              placeholder="Eg. 8587933843" 
              onChange={handleChange}
              required 
            />
            <mb-label htmlFor="email">Email</mb-label>
            <input 
              type="email" 
              name="email" 
              placeholder="Eg. karansharma@gmail.com" 
              onChange={handleChange}
              required 
            />
            <mb-label htmlFor="product">Product</mb-label>
            <input 
              type="text" 
              name="product" 
              placeholder="Eg. E-Step Toyota Fortuner 2016 With LED" 
              onChange={handleChange}
              required 
            />
            <mb-label htmlFor="purchase_date">Date of Purchase</mb-label>
            <input 
              type="date" 
              name="purchaseDate" 
              placeholder="DD/MM/YYYY" 
              onChange={handleChange}
              required 
            />
            <mb-label htmlFor="invoice">Upload Invoice</mb-label>
            <input 
              type="file" 
              id="invoice" 
              name="invoice" 
              onChange={handleChange}
              required 
            />
            <mb-button type="button" onClick={handleSubmit} disabled={!formValid}>Submit</mb-button>
          </mb-form>
        </div>
      </div>
      {isLoading && <div className="loader">Uploading File...</div>}
    </div>
  );
};

export default MobileView;
